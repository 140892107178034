<template>
    <div class="m-p">
       <Consulting />
    </div>
 </template>
 <script>
 import { defineAsyncComponent } from 'vue'
 export default {
 name: 'My Commercial',
 components: {
    Consulting: defineAsyncComponent( () => import('@/components/Profile/Consulting.vue') ),
 },
 }
 </script>